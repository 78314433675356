var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subpage-layout submission" }, [
    _c(
      "div",
      { staticClass: "showcase-sidebar" },
      [
        _c("back-button", {
          attrs: {
            "showcase-id": _vm.showcaseId,
            "button-text": "Back to Showcase",
          },
        }),
        _vm._v(" "),
        _c("info"),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showcase
      ? _c(
          "div",
          { staticClass: "info-container" },
          [
            _c(
              "div",
              { staticClass: "show-mobile" },
              [
                _c("back-button", {
                  attrs: {
                    "showcase-id": _vm.showcaseId,
                    "button-text": "Back to Showcase",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.sliderData
              ? _c(
                  "div",
                  [
                    _c("ShowcaseSlider", {
                      attrs: {
                        id: "showcase_submission_slider",
                        title: false,
                        slides: _vm.sliderData,
                        "slideshow-delay": "7000",
                        "show-controls": _vm.sliderData.length > 1,
                        "show-indicators": _vm.sliderData.length > 1,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("h2", [_vm._v("Project objective")]),
            _vm._v(" "),
            _vm.submission
              ? _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.submission.description,
                      expression: "submission.description",
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _c("h2", [_vm._v("Audience insight or inspiration")]),
            _vm._v(" "),
            _vm.submission
              ? _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.insight,
                      expression: "insight",
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _c("info", { attrs: { classes: "show-mobile info-wrapper" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "submission-navigation" },
              [
                _vm.isPublished
                  ? _c("ShowcaseNavigationButtons", {
                      on: { navClick: _vm.onNavClick },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }