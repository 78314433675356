var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "landing-page" },
    [
      _c("ShowcaseOverview", {
        attrs: { showcase: _vm.showcase, "showcase-id": _vm.showcaseId },
      }),
      _vm._v(" "),
      _vm.featuredSubmissions.length > 0 ? _c("ShowcaseFeatured") : _vm._e(),
      _vm._v(" "),
      _vm.showcase ? _c("ShowcaseGrid") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }