var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "client-submission-info", class: _vm.classes },
    [
      _vm.businessLine
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Business line\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.businessLine,
                  expression: "businessLine",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.teamMember
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Team member names\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.teamMember,
                  expression: "teamMember",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.audience
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Audience\n    "),
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "sanitize",
                  rawName: "v-sanitize",
                  value: _vm.audience,
                  expression: "audience",
                },
              ],
              staticClass: "info-content",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.links
        ? _c("div", { staticClass: "info-block" }, [
            _c("p", { staticClass: "info-title" }, [
              _vm._v("\n      Related links:\n    "),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "links" },
              _vm._l(_vm.links, function (link, index) {
                return _c("li", { key: index }, [
                  _c("a", { attrs: { href: link.url, target: "_blank" } }, [
                    _vm._v(_vm._s(link.text)),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }