var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "landing-page" },
    [
      _c("ShowcaseOverview", {
        attrs: { showcase: _vm.showcase, "showcase-id": _vm.showcaseId },
      }),
      _vm._v(" "),
      _vm.featuredSubmissions.length > 0 ? _c("ShowcaseFeatured") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "join-showcase" },
        [
          _c("hr"),
          _vm._v(" "),
          _c("h1", { staticClass: "title" }, [
            _vm._v("\n      Join the showcase\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "subtitle-home" }, [
            _vm._v("\n      Share your most groundbreaking work.\n    "),
          ]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn primary",
              attrs: {
                to: _vm.$generateRoute({
                  name: "showcase.submit",
                  params: {
                    showcaseId: _vm.showcaseId,
                  },
                }),
              },
            },
            [_vm._v("\n      Submit my project\n    ")]
          ),
          _vm._v(" "),
          _c("hr"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showcase ? _c("ShowcaseGrid") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }